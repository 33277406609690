<template>
  <div>
    <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" @click="showAddSaleModal" icon="el-icon-user"
      round>Add Sale</el-button>
    <article>
      <section>
        <div class="">
          <el-dialog :title="`Add Sale`" :visible.sync="addSaleModal" :destroy-on-close="true"
            :before-close="handleClose" style="text-align: left">
            <div v-loading="submitting">
              <el-form :model="saleForm" :rules="rules" label-position="top" ref="saleForm" class="demo-saleForm2">
                <div class="row">
                  <div class="col-md-12">
                      {{ product }}
                      <el-form-item label="Product" prop="productID">
                        <el-select v-model="saleForm.productID" @change="getOneProduct(saleForm.productID)" placeholder="Select">
                          <el-option
                            v-for="item in products"
                            :key="item.ProductID"
                            :label="item.ProductName"
                            :value="item.ProductID">
                          </el-option>
                        </el-select>
                      </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <el-form-item label="Total Price" prop="totalPrice">
                      <el-input v-model="saleForm.totalPrice" @keyup.enter.native="addSale('saleForm')"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <el-form-item label="Quantity Sold" prop="quantitySold">
                      <el-input v-model="saleForm.quantitySold" @keyup.enter.native="addSale('saleForm')"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <el-form-item label="Unit Price" prop="unitPrice">
                      <el-input v-model="saleForm.unitPrice" @keyup.enter.native="addSale('saleForm')"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button type="success" round @click="addSale('saleForm')">Add Sale
                    </el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>Design by <a href="https://ovalspace.co"
                      style="color: black;"><strong>Ovalspace</strong></a></small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      product: {},

      addSaleModal: false,
      editWarehouseModal: false,
      value: "",
      loadingError: false,
      submitting: false,
      saleForm: {
          productID: "",
          quantitySold: "",
          totalPrice: "",
          unitPrice: ""
      },

      rules: {
        productID: [ 
          { required: true, message: "Product is required", trigger: "blur", },
        ],
        quantitySold: [
          { required: true, message: "Quantity Sold is required", trigger: "blur" },
        ],
        totalPrice: [
          { required: true, message: "Total Price is required", trigger: "blur" },
        ],
        unitPrice: [
          { required: true, message: "Unit Price is required", trigger: "blur" },
        ]
      },
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    showAddSaleModal() {
      this.addSaleModal = true;
      this.getProducts();
      this.saleForm.productID = "";
      this.saleForm.quantitySold = "";
      this.saleForm.unitPrice = "";
      this.saleForm.totalPrice = "";
    },

    async addSale(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(`sales`, {
              productID: this.saleForm.productID,
              quantitySold: this.saleForm.quantitySold,
              unitPrice: this.saleForm.unitPrice,
              totalPrice: this.saleForm.totalPrice,
              addedBy: this.$store.state.userId,
              company_id: this.$store.state.company.company_id,
            });
            if (
              response.data.success &&
              response.data.message == "Sale created successfully"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "Sale added successfully",
                type: "success",
              });
              this.addSaleModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Add Sale",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  
    async getProducts() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `products/get-products/${this.$store.state.company.company_id}`
        );
        if (request.data.success) {
          this.products = request.data.products;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch products now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    
    async getOneProduct(productID) {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `products/${productID}`
        );
        if (request.data.success) {
          this.product = request.data.product;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch products now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

<style></style>