<template>
    <div>
      <article>
        <section>
          <div class="courseUnits_dialogs">
            <el-dialog
              :title="`Update A User`"
              :visible.sync="isEditUserModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="userForm"
                  :rules="rules"
                  label-position="top"
                  ref="userForm"
                  class="demo-userForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="First Name"
                        prop="first_name"
                      >
                        <el-input v-model="userForm.first_name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Last Name"
                        prop="last_name"
                      >
                        <el-input v-model="userForm.last_name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Phone"
                        prop="phone"
                      >
                        <el-input v-model="userForm.phone"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Email"
                        prop="email"
                      >
                        <el-input v-model="userForm.email"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Username"
                        prop="username"
                      >
                        <el-input v-model="userForm.username"></el-input>
                      </el-form-item>
                    </div>
  
                    <!-- <div class="col-md-6">
                      <el-form-item
                        label="Password"
                        prop="password"
                      >
                        <el-input v-model="userForm.password"></el-input>
                      </el-form-item>
                    </div> -->
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Choose User Role"
                        prop="role"
                      >
                        <el-select
                          v-model="userForm.role"
                          placeholder="Select"
                          class="w-100"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Choose Gender"
                        prop="gender"
                      >
                        <el-select
                          v-model="userForm.gender"
                          placeholder="Select"
                          class="w-100"
                        >
                          <el-option
                            v-for="gender in genders"
                            :key="gender.id"
                            :label="gender.title"
                            :value="gender.title"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editUser('userForm')"
                    > Save User Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Buddo Secondary School, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
        bookings: [],
        users: [],
        userEditId: "",
        isUploadingFile: false,
        genders: [
          { id: 1, title: "Male" },
          { id: 1, title: "Female" },
        ],
  
        options: [
          {
            value: "AGENT",
            label: "Agent",
          },
          {
            value: "ADMIN",
            label: "Admin",
          },
        ],
  
        isEditUserModalVisible: false,
        years: "",
        year_numbers: [],
        value: "",
        loadingError: false,
        submitting: false,
        course: "",
        userForm: {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          username: "",
          role: "",
          gender: "",
        },
  
        rules: {
          first_name: [
            {
              required: true,
              message: "First Name is required",
              trigger: "blur",
            },
          ],
          last_name: [
            { required: true, message: "Last Name is required", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "Phone is required", trigger: "blur" },
          ],
          email: [
            { required: true, message: "Email is required", trigger: "blur" },
          ],
          username: [
            {
              required: true,
              message: "Username is required",
              trigger: "change",
            },
          ],
          gender: [
            {
              required: true,
              message: "Gender is required",
              trigger: "change",
            },
          ],
          role: [
            {
              required: true,
              message: "User Role is required",
              trigger: "change",
            },
          ],
        },
      };
    },
  
    props: {
      showUserEditDialog: {
        required: true,
        type: Boolean,
      },
      userData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showUserEditDialog() {
        if (this.showUserEditDialog === true) {
          this.isEditUserModalVisible = true;
          this.userForm.first_name = this.userData.first_name;
          this.userForm.last_name = this.userData.last_name;
          this.userForm.username = this.userData.username;
          this.userForm.email = this.userData.email;
          this.userForm.phone = this.userData.phone;
          this.userForm.role = this.userData.role;
          this.userForm.gender = this.userData.gender;
        } else {
          this.isEditUserModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditUserDialog");
      },
  
      async editUser(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.patch(
                `users/${this.userData.user_id}`,
                {
                  first_name: this.userForm.first_name,
                  last_name: this.userForm.last_name,
                  username: this.userForm.username,
                  email: this.userForm.email,
                  phone: this.userForm.phone,
                  role: this.userForm.role,
                  gender: this.userForm.gender,
                }
              );
              if (
                response.data.success &&
                response.data.message == "USER_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "User updated successfully",
                  type: "success",
                });
                this.$emit("closeEditUserDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update user",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
    
  <style scoped>
  
    fieldset {
        background-color: rgb(219, 236, 238);
          border: 1px solid powderblue;
          color: black;
          padding: 20px;
          border-radius: 5px;
          box-shadow: rgba(176, 224, 230, 0.8) 0px 1px 2px 0px;
          /* background: powderblue; */
          margin-bottom: 50px;
      }
  
      fieldset:hover{
          box-shadow: rgba(176, 224, 230, 0.24) 0px 3px 8px;
      }
  
      legend {
        border: 2px solid powderblue;
        background-color: powderblue;
          box-shadow: rgba(176, 224, 230, 0.8) 0px 1px 2px 0px;
          border-radius: 5px;
        width: 100%;
          font-size: 18px;
          padding: 20px;
      }
  
  </style>
  
  <style>
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }
    .el-input__inner {
        width: 100%;
    }
  </style>